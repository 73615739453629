.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 90.39px;
}
.main-footer {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.phone-footer {
  display: flex;
}
.email {
  display: flex;
  margin-top: 29.03px;
}
.line {
  width: 1px;
  height: 45.97px;
  border: 1px solid #e2e2e2;
  background: #404040;
  margin-right: 21px;
}
.footer-phone-div {
  margin-left: 24.58px;
}
.footer-phone-text {
  color: #bc2423;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  font-family: "Poppins", sans-serif;
}
.footer-phone-number {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  width: 187px;
  font-family: "Poppins", sans-serif;
}
.responsive-line {
  display: none;
}
.use-ful-link {
  color: #bc2423;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  font-family: "Poppins", sans-serif;
}
.link {
  display: flex;
  align-items: center;
}
.link-text {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 50px;
  margin-left: 10px;
  cursor: pointer;
  font-family: "Poppin", sans-serif;
}
.logo-container {
  display: flex;
  align-items: center;
}
.logo-text {
  color: #bc2423;
  font-size: 34px;
  font-weight: 600;
  line-height: normal;
  margin-left: 14.59px;
}
.read-more-para {
  color: #575757;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  width: 450px;
  /* margin-top: 33px; */
  font-family: "Poppins", sans-serif;
}
.read-more-text {
  color: #fb991c;
  font-size: 14px;
  font-weight: 400;
  line-height: 50px;
  margin-top: 20.1px;
  cursor: pointer;
}

.footer-line {
  background: #e2e2e2;
  width: 95%;
  margin-top: 78.4px;
  margin-bottom: 16.5;
}
.scroll-and-copy-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  padding: 10px 0px;
}
.copy-right-div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.cursor {
  cursor: pointer;
}
.copy-right-text {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Poppins";
}
.best-tech {
  color: #bc2423;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.social-links {
  display: flex;
  align-items: center;
  gap: 5px;
}
.instagram-link {
  text-decoration: none;
  color: #000;
  font-family: "Poppins", sans-serif;
  margin-top: 20px;
  font-size: 0.8rem;
  font-weight: 600;
}
@media (max-width: 600px) {
  .social-links {
    display: none;
  }
  .cursor {
    display: none;
  }
  .read-more-para {
    text-align: center;
    width: 100%;
  }
}
@media (max-width: 800px) {
  .read-more-text {
    text-align: center;
  }
  .read-more-para {
    text-align: center;
  }
  .logo-container {
    justify-content: center;
  }
  .links-container {
    display: none;
  }
  .responsive-line {
    display: block;
    background: #e2e2e2;
    width: 95%;
    margin: 40px 0;
  }
  .line {
    display: none;
  }
  .main-footer {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 1024px) {
  .read-more-text {
    text-align: center;
  }
  .read-more-para {
    text-align: center;
  }
  .logo-container {
    justify-content: center;
  }
  .links-container {
    display: none;
  }
  .responsive-line {
    display: block;
    background: #e2e2e2;
    width: 95%;
    margin: 40px 0;
  }
  .line {
    display: none;
  }
  .main-footer {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 1280px) {
  .main-footer {
    width: 95%;
  }
}
@media (max-width: 400px) {
  .responsive-line {
    margin: 20px 0;
  }
}
