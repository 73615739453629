@import "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background-color: #fff;
}

body::-webkit-scrollbar-thumb {
  background-color: #bc2423;
  border-radius: 10px;
}

::selection {
  color: #fff;
  background: #bc2423;
}

.main-container-wrapper {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
}

.navbar-and-banner {
  background-image: linear-gradient(
    55deg,
    rgba(173, 1, 0, 0.89) 0%,
    rgba(0, 0, 0, 1) 50%,
    rgba(173, 1, 0, 0.89) 100%
  );
}
.scroll-border::-webkit-scrollbar-thumb {
  border-radius: 0;
}
.toastify {
  z-index: 99999999999999999;
}

.body-modal-open {
  overflow: hidden !important;
}
.time-out-modal-open {
  overflow: hidden !important;
}
.modal-open {
  overflow: hidden !important;
}


.appbar_area{
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  color: #BC2423;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: sticky;
  top: 0;
  z-index: 99999;
  font-size: 14px;
}

@media (max-width: 400px) {
  .appbar_area{
    font-size: 11px;
  }
}