.checkout-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0 0;
  margin-left: auto;
  margin-right: auto;
}

.checkout-header img {
  /* min-width: 125px;
  min-height: 125px; */
  width: 120px;
  height: 100px;
}

.checkout-page-content {
  margin-bottom: 3rem;
}

.main-checkout-container {
  /* max-width: 90%; */
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
}

.main-container {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 100px;
}

.form-container {
  width: 62%;
  background-color: #f7f7f7;
  border-radius: 15px;
  padding: 25px;
}

.place-order-container {
  width: 35%;
  background-color: #f7f7f7;
  border-radius: 15px;
  padding: 25px;
}

.form-title {
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
  line-height: 1.5rem;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.text-uppercase {
  text-transform: uppercase;
}

.form-data {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 20px; */
}

.form-title-div {
  width: 15%;
}

.dropdown-and-input {
  background: #fff;
  border: 0;
  box-shadow: 0 6px 5px rgb(0 0 0/5%);
  color: #1e1e1e;
  font-size: 0.9rem;
  border-radius: 0.4rem;
  margin-bottom: 1.5rem;
  margin-top: 10px;
}

.input-main-container {
  border-radius: 4px;
  padding: 18px 12px;
  cursor: pointer;
  display: flex;
  cursor: pointer;
}

.title-dropdown {
  border: none;
  outline: none;
  width: 100%;
  font-size: 0.9rem;
  font-weight: 600;
  color: inherit;
}

.title-dropdown::placeholder {
  font-size: 0.9rem;
  font-weight: 600;
  color: inherit;
}

.name-input-div {
  background: #fff;
  box-shadow: 0 6px 5px rgb(0 0 0/5%);
  color: #1e1e1e;
  font-size: 0.9rem;
  border-radius: 0.4rem;
  margin-bottom: 1.5rem;
  margin-top: 10px;
  border: none;
  font-family: "Poppins", sans-serif;
}

.lable {
  color: #212529;
  padding-bottom: 5px;
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 1.5rem;
}

.simple-input {
  width: 100%;
  border: none;
  padding: 20px;
  border-radius: 12px;
  outline: none;
  color: #1e1e1e;
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.simple-input::placeholder {
  color: #70757d;
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.simple-input:focus {
  outline: none;
}

.location-text {
  line-height: 2.2rem;
  font-size: 0.9rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #000;
}

.location-div {
  padding: 0.48rem 0.75rem;
  background-color: #e5ecef !important;
}

.location-input {
  background: transparent;
  border: none;
  width: 100%;
  padding: 20px;
  border-radius: 12px;
  outline: none;
  color: #1e1e1e;
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
}

.location-input::placeholder {
  color: #70757d;
}

.location-input:focus {
  outline: none;
}

.instructions {
  border: none;
  width: 100%;
  padding: 20px;
  outline: none;
  border-radius: 12px;
  color: #1e1e1e;
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.instructions::placeholder {
  color: #70757d;
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.instructions:focus {
  outline: none;
}

.Payment-information-container p {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
}

.cash-div {
  width: 30%;
  height: 90px;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #bc2423;
  flex-direction: column;
  border-radius: 0.3rem;
  margin-right: 20px;
  text-align: center;
  cursor: pointer;
}

.cash-div:focus {
  border: 1px solid #000;
}

.payment-style {
  width: 30%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #bc2423; */
  flex-direction: column;
  border-radius: 0.3rem;
  margin-right: 20px;
  text-align: center;
  cursor: pointer;
  /* padding: 5px 10px; */
  /* background-color: #bc2423; */
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transform: scale(1);
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.cash-div span {
  font-family: "Poppins", sans-serif;
  color: #212529;
  font-size: 0.9rem;
  line-height: 1.5rem;
  font-weight: 600;
  text-transform: capitalize;
}

.payment-style span {
  font-family: "Poppins", sans-serif;
  color: #000;
  font-size: 0.9rem;
  line-height: 1.5rem;
  font-weight: 600;
  text-transform: capitalize;
}

.payment-method {
  display: flex;
  /* align-items: center; */
  margin-top: 20px;
  /* justify-content: center; */
}

.change-request {
  margin-top: 20px;
}

.change-request p {
  font-size: 0.9rem;
  line-height: 1.5rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.change-input-field {
  width: 30%;
  /* width: 50%; */
  margin-top: 10px;
  background: #fff;
  box-shadow: 0 6px 5px rgb(0 0 0/5%);
  border-radius: 12px;
}

.change-input {
  border: none;
  padding: 18px;
  border-radius: 12px;
  /* margin-left: 15px; */
  color: #1e1e1e;
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  width: 80%;
}

.change-input::placeholder {
  color: #70757d;
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.change-input:focus {
  outline: none;
}

.change-input-text {
  font-size: 0.9rem;
  line-height: 1.5rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  padding: 15px 0px;
  border-radius: 12px;
  background: #fff;
  padding-left: 15px !important;
}

.place-order-item-container {
  margin-bottom: 40px;
}

.place-order-price-container {
  width: 30%;
}

.place-order-price {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  /* text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; */
  text-align: right;
}

.place-order-quantity {
  font-size: 0.9rem;
  line-height: 1.5rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  /* text-align: right; */
}

.place-order-description {
  font-size: 0.9rem;
  line-height: 1.5rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  /* text-align: right; */
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;  
}

.place-order-detail1,
.place-order-detail2,
.place-order-total,
.place-order-grand-total {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  width: 100%;
}

.place-order-text {
  font-size: 1rem;
  font-weight: 600;
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: "Poppins", sans-serif;
  width: 75%;
}

.place-order-detail1 {
  padding: 0px;
}

.place-order-detail2 {
  font-size: 0.9rem;
  font-weight: 300;
  padding: 0px;
}

.place-order-remove {
  cursor: pointer;
  color: #1b00ff;
  font-size: 0.9rem;
  font-weight: 500;
  margin: 10px 0px;
}

.place-order-prices {
  float: right;
}

.place-order-grand-total {
  font-weight: 600;
}

.place-order-total-container {
  margin: 40px 0px !important;
}

.place-order-button {
  width: 100%;
  height: 49px;
  margin: 20px 0px;
  border: none;
  color: white;
  background-color: #bc2423;
  padding: 0.7rem 1rem !important;
  font-size: 1rem !important;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  border-radius: 8px;
}

.back-to-home {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1b00ff;
  font-size: 0.9rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.form-address {
  display: flex;
  flex-direction: row;
}

.title-dropdown-list {
  display: none;
  position: absolute;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 1;
}

.title-dropdown-option {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
}

.title-dropdown-option:hover {
  background-color: rgba(38, 132, 255, 0.1);
}

.input-main-container:focus+.options-list {
  display: block;
}

.title-dropdown-open {
  display: block;
  position: absolute;
  width: 7.5%;
  border-radius: 0 0 4px 4px;
  min-height: 38px;
  transition: all 100ms ease 0s;
  background-color: rgb(255, 255, 255);
  border-color: rgb(204, 204, 204);
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  outline: 0px !important;
}

.full-name-main-container {
  width: 80%;
}

.second-input-div {
  width: 45%;
}

.area-input-div {
  width: 25%;
}

.deliver-address-input-div {
  width: 65%;
}

.total-main-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.3rem;
}

.total-price-text,
.total-price-number {
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
}

.simple-input:hover::placeholder {
  color: transparent;
}

.instructions:hover::placeholder {
  color: transparent;
}

.place-order-product {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  width: 80%;
}

/* .image-background-div {
  padding: 10px;
  border-radius: 12px;
  background: #bc24238a;
  display: flex;
  align-items: center;
  justify-content: center;
} */
.place-order-itme-image {
  border-radius: 10px;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.place-order-text-description {
  font-size: 0.8rem;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.place-order-wrapper {
  /* border-radius: 12px; */
  border-bottom: 0.2rem solid #e2e2e2;
  padding: 15px 0;
  margin-bottom: 20px;
  display: flex;
  /* align-items: center; */
  /* justify-content: space-between; */
  /* padding: 15px 25.5px 15px 13px; */
}

.place-order-product-remove {
  width: 30px !important;
  height: 30px !important;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.21);
  border-radius: 100rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.place-order-product-remove:hover {
  background-color: #af4848;
}

.order-place-price-and-remove {
  display: flex;
  align-items: center;
  justify-content: end;
}

.order-placed-text-description {
  width: 90%;
}

@media (max-width: 1440px) {
  .checkout-header {
    max-width: 1280px;
  }

  .container {
    max-width: 1280px;
  }

  /* 
  .order-placed-text-description {
    width: 75%;
  } */
  /* .change-input {
    width: 87%;
  } */
}

@media (max-width: 1280px) {

  /* .change-input {
    width: 85%;
  } */
  .input-main-container {
    padding: 18px 10px;
  }

  .checkout-header {
    max-width: 1024px;
  }

  .container {
    max-width: 1024px;
  }

  /* .order-placed-text-description {
    width: 72%;
  } */
}

@media (max-width: 1024px) {
  .title-dropdown-open {
    width: 11%;
  }

  .payment-style {
    width: 25%;
  }

  .cash-div {
    width: 25%;
  }

  .checkout-header {
    max-width: 800px;
  }

  .container {
    max-width: 800px;
  }

  .main-container {
    display: flex;
    flex-direction: column;
  }

  .place-order-container {
    width: 100%;
    padding-left: 50px !important;
    padding-right: 50px !important;
    margin-top: 50px;
  }

  .form-container {
    width: 100%;
    padding-left: 50px !important;
    padding-right: 50px !important;
    margin-top: 30px;
  }

  /* .order-placed-text-description {
    width: 90%;
  } */
}

@media (max-width: 800px) {

  /* .change-input {
    width: 85%;
  } */
  .order-placed-text-description {
    width: 87%;
  }

  .payment-style {
    width: 30%;
  }

  .cash-div {
    width: 30%;
  }

  .area-input-div {
    width: 100%;
  }

  .deliver-address-input-div {
    width: 100%;
  }

  .title-dropdown-open {
    width: 77.5%;
  }

  .form-title-div {
    width: 100%;
  }

  .second-input-div {
    width: 100%;
  }

  .checkout-header {
    max-width: 600px;
  }

  .container {
    max-width: 600px;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }

  .input-main-container {
    padding: 18px 20px;
  }

  .customer-info-form {
    padding: 3rem !important;
  }

  .place-order-container {
    width: 100%;
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-top: 50px;
  }

  .form-container {
    width: 100%;
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-top: 30px;
  }

  .simple-input {
    width: 100%;
  }

  .full-name-main-container {
    width: 100%;
  }

  .form-data {
    flex-direction: column;
    width: 100%;
    align-items: start;
  }

  .total-main-check {
    padding: 0;
  }

  .change-input-field {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .change-input-field {
    width: 100%;
  }

  .order-placed-text-description {
    width: 82%;
  }

  .title-dropdown-open {
    width: 75%;
  }

  .checkout-header {
    max-width: 400px;
  }

  .container {
    max-width: 400px;
  }

  .main-container {
    display: flex;
    flex-direction: column;
  }

  .payment-style span {
    font-family: "Poppins", sans-serif;
    /* color: #fff; */
    font-size: 0.7rem;
    line-height: 1.5rem;
    font-weight: 400;
  }

  .cash-div {
    margin-top: 10px;
    width: 50%;
  }

  .payment-style {
    margin-top: 10px;
    width: 50%;
  }

  .payment-method {
    flex-direction: column;
  }
}

@media (max-width: 500px) {
  .title-dropdown-open {
    width: 71.5%;
  }

  .order-placed-text-description {
    width: 78%;
  }

  .cash-div span {
    font-family: "Poppins", sans-serif;
    font-size: 0.7rem;
    line-height: 1.5rem;
    font-weight: 400;
  }

  .payment-method {
    flex-direction: column;
  }

  .cash-div {
    margin-top: 10px;
    width: 50%;
  }

  .payment-style {
    margin-top: 10px;
    width: 50%;
  }
}

@media (max-width: 400px) {
  .total-price-text {
    width: 60% !important;
  }

  .total-price-number {
    width: 40% !important;
  }

  .place-order-product {
    width: 70%;
  }

  .place-order-price-container {
    width: 30%;
  }

  .order-placed-text-description {
    width: 70%;
  }

  .title-dropdown-open {
    width: 70%;
  }

  .payment-method {
    flex-direction: column;
  }

  .cash-div {
    margin-top: 10px;
    width: 50%;
  }

  .payment-style {
    margin-top: 10px;
    width: 50%;
  }
}