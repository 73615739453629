/* woff2 : Super Modern Browsers */
/* woff2 :Modern Browsers */

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  src: url("./assets/fonts/Poppins-Thin.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Thin.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  src: url("./assets/fonts/Poppins-ExtraLight.woff2") format("woff2"),
    url("./assets/fonts/Poppins-ExtraLight.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/Poppins-Light.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Light.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Poppins-Regular.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/Poppins-Medium.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Medium.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Poppins-SemiBold.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/Poppins-Bold.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Bold.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  src: url("./assets/fonts/Poppins-ExtraBold.woff2") format("woff2"),
    url("./assets/fonts/Poppins-ExtraBold.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  src: url("./assets/fonts/Poppins-Black.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Black.woff") format("woff");
  font-display: swap;
}
