/* .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999999;
  backdrop-filter: 1px;
  backdrop-filter: blur(10px);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
} */
.loaderTyping {
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  /* height: 100vh; */
  height: 100%;
  position: fixed;
}
.loader-logo {
  width: 20%;
  /* height: 30%; */
  margin-bottom: 10px;
}
@media (max-width: 1024px) {
  .loader-logo {
    width: 30%;
    /* height: 30%; */
  }
}
@media (max-width: 800px) {
  .loader-logo {
    width: 40%;
    /* height: 30%; */
  }
}
@media (max-width: 600px) {
  .loader-logo {
    width: 50%;
    /* height: 30%; */
  }
}
@media (max-width: 500px) {
  .loader-logo {
    width: 60%;
    /* height: 30%; */
  }
}
