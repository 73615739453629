.notfound-container {
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  /* height: 100vh; */
}
.notfound-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  flex-direction: column;
}
.image-container {
  margin-bottom: 10px;
}
.image-notfound {
  width: 250px;
}
.notfound-text {
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
  line-height: 1.2;
}
.go-back-notfound {
  background-color: #bc2423;
  margin-top: 10px;
  border: none;
  border-radius: 15px;
  width: 200px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
  cursor: pointer;
}
