.thankyou-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999999;
}
.thankyou-wrapper {
  max-width: 400px;
  padding: 20px 0;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 1rem !important;
  background-color: #fff;
  width: 100%;
  position: relative;
}
.thankyou-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
}
.thankyou-wrapper h3 {
  font-size: 1rem;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  color: #1e1e1e;
}
.thankyou-wrapper p {
  font-size: 0.8rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #3f3f3f;
}
.back-home-page {
  margin-top: 10px;
  background-color: #bc2423;
  border-radius: 0.4rem;
  color: #fff;
  border: 0;
  padding: 10px 20px;
  font-size: 0.8rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  cursor: pointer;
}
@media (max-width: 400px) {
  .thankyou-wrapper {
    max-width: 300px;
  }
}
